<template>
  <div class="MachineDetail">
    <div class="goback" @click="goback">返回上一级</div>
    <div class="MachineContent">
      <div class="leftContent">
        <div class="seltab">
          日期筛选 &nbsp;
          <el-radio-group
            v-model="tabPosition"
            size="mini"
            @change="changedatetype"
          >
            <el-radio-button label="DAYS">日</el-radio-button>
            <el-radio-button label="MONTHS">月</el-radio-button>
            <el-radio-button label="YEARS">年</el-radio-button>
          </el-radio-group>
        </div>

        <div class="lefttab" style="left: 4%; top: 3%">
          <div class="tabtit">
            {{
              tabPosition === "DAYS"
                ? "当日"
                : tabPosition === "MONTHS"
                ? "当月"
                : "今年"
            }}产量
          </div>
          <img
            src="~@/assets/workshopMonitor/detail/little1.webp"
            alt=""
            style="margin-top: 12px"
          />
          <div class="num1">{{ isdayyield.toFixed(2) }}</div>
          <div class="num2">t</div>
        </div>
        <div class="lefttab" style="left: 4%; top: 36%">
          <div class="tabtit">电量单耗</div>
          <img
            src="~@/assets/workshopMonitor/detail/dldh.webp"
            alt=""
            style="margin-top: 12px"
          />
          <div class="num1">
            {{
              ElectricityConsumption != "--"
                ? ElectricityConsumption.toFixed(2)
                : "--"
            }}
          </div>
          <div class="num2">kwh</div>
        </div>
        <div class="lefttab" style="left: 4%; top: 69%">
          <div class="tabtit">燃气单耗</div>
          <img
            src="~@/assets/workshopMonitor/detail/rqdh.webp"
            alt=""
            style="margin-top: 12px"
          />
          <div class="num1">
            {{ GasConsumption != "--" ? GasConsumption.toFixed(2) : "--" }}
          </div>
          <div class="num2">m³</div>
        </div>
        <div class="lefttab" style="right: 4%; top: 12%">
          <div class="tabtit">压空单耗</div>
          <img
            src="~@/assets/workshopMonitor/detail/littlt4.webp"
            alt=""
            style="margin-top: 12px"
          />
          <div class="num1">
            {{ AirConsumption != "--" ? AirConsumption.toFixed(2) : "--" }}
          </div>
          <div class="num2">m³</div>
        </div>
        <div class="lefttab" style="right: 4%; top: 62%">
          <div class="tabtit">蒸汽单耗</div>
          <img
            src="~@/assets/workshopMonitor/detail/little5.webp"
            alt=""
            style="margin-top: 12px"
          />
          <div class="num1">--</div>
          <div class="num2">m³</div>
        </div>
        <div class="bigbg">
          <div class="jtit">H27501T挤压机台</div>
          <div class="sclogo">
            <span>生产中</span>
            <span>功率:35KW</span>
          </div>
          <div class="jt">
            <img
              src="~@/assets/workshopMonitor/detail/jtyx.webp"
              alt=""
              style="margin-top: 12px"
            />
          </div>
        </div>
      </div>
      <div class="rightContent">
        <div class="riHead">
          <span>消耗指标分析</span>
        </div>
        <div class="ritMsg">
          <div class="tabsimg1">
            <div class="t1">{{ TotalElectricity }} <span>kwh</span></div>
            <div class="direction">
              <div class="t2">电</div>
            </div>
          </div>
          <div class="tabs">
            <div class="atab">
              <div class="leftTxt">2750主机</div>
              <div class="ritTxt">
                {{ machineHost != "--" ? machineHost.toFixed(0) : "--" }}
                <span>kwh</span>
              </div>
            </div>
            <div class="atab">
              <div class="leftTxt">2750动力</div>
              <div class="ritTxt">
                {{ machinePower != "--" ? machinePower.toFixed(0) : "--" }}
                <span>kwh</span>
              </div>
            </div>
            <div class="atab">
              <div class="leftTxt">水淬</div>
              <div class="ritTxt">
                {{ TotalWater != "--" ? TotalWater.toFixed(0) : "--" }}
                <span>kwh</span>
              </div>
            </div>
            <div class="atab">
              <div class="leftTxt">冷床</div>
              <div class="ritTxt">
                {{ coldBed != "--" ? coldBed.toFixed(0) : "--" }}
                <span>kwh</span>
              </div>
            </div>
            <div class="atab">
              <div class="leftTxt">热剪炉</div>
              <div class="ritTxt">
                {{ stove != "--" ? stove.toFixed(0) : "--" }} <span>kwh</span>
              </div>
            </div>
            <div class="atab">
              <div class="leftTxt">模具炉</div>
              <div class="ritTxt">
                {{ mould != "--" ? mould.toFixed(0) : "--" }} <span>kwh</span>
              </div>
            </div>
            <div class="atab">
              <div class="leftTxt">牵引机</div>
              <div class="ritTxt">
                {{
                  tractionMachine != "--" ? tractionMachine.toFixed(0) : "--"
                }}
                <span>kwh</span>
              </div>
            </div>
          </div>
          <div class="tabsimg1">
            <div class="t1">
              {{ Water != "--" ? Water.toFixed(0) : "--" }} <span>t</span>
            </div>
            <div class="direction tab2">
              <div class="t2">水</div>
            </div>
          </div>
          <div class="tabsimg1 bg1">
            <div class="t1">
              {{ naturalGas != "--" ? naturalGas.toFixed(0) : "--" }}
              <span>m³</span>
            </div>
            <div class="direction tab3">
              <div class="t2" style="right: -51px">天然气</div>
            </div>
          </div>
          <div class="tabsimg1 bg2">
            <div class="t1">
              {{ compressedAir != "--" ? compressedAir.toFixed(0) : "--" }}
              <span>m³</span>
            </div>
            <div class="direction tab4">
              <div class="t2" style="right: -62px">压缩空气</div>
            </div>
          </div>
          <div class="tabs">
            <div class="atab">
              <div class="leftTxt">南支管(DN25)</div>
              <div class="ritTxt">
                {{
                  compressedAirSouth != "--"
                    ? compressedAirSouth.toFixed(0)
                    : "--"
                }}
                <span>m³</span>
              </div>
            </div>
            <div class="atab">
              <div class="leftTxt">北支管(DN20)</div>
              <div class="ritTxt">
                {{
                  compressedAirNorth != "--"
                    ? compressedAirNorth.toFixed(0)
                    : "--"
                }}
                <span>m³</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getWorkshopMonitoringData, screenQuery } from "@/request/apiLg";
import dayjs from "dayjs"; // 导入日期js
export default {
  name: "",
  data() {
    return {
      tabPosition: "DAYS",
      TotalElectricity: "--",
      TotalWater: "--",
      machineHost: "--",
      machinePower: "--",
      coldBed: "--",
      stove: "--",
      mould: "--",
      tractionMachine: "--",
      Water: "--",
      naturalGas: "--",
      compressedAir: "--",
      compressedAirSouth: "--",
      compressedAirNorth: "--",
      starttime: dayjs().startOf("day").format("YYYY-MM-DD 00:00:00"),
      endtime: dayjs()
        .add(1, "day")
        .startOf("day")
        .format("YYYY-MM-DD 00:00:00"),
      isdayyield: 1.5 * 24, //当日产量
      // ElectricityConsumption: 0, // 电量消耗
      // GasConsumption:0,// 燃气单耗
      // AirConsumption:0,// 压空单耗
    };
  },
  computed: {
    ElectricityConsumption() {
      if (this.TotalElectricity != "--") {
        return this.TotalElectricity / this.isdayyield;
      } else {
        return "--";
      }
    },
    GasConsumption() {
      if (this.naturalGas != "--") {
        return this.naturalGas / this.isdayyield;
      } else {
        return "--";
      }
    },
    AirConsumption() {
      if (this.compressedAir != "--") {
        return this.compressedAir / this.isdayyield;
      } else {
        return "--";
      }
    },
    // TotalElectricity() {
    //   if (
    //     this.TotalWater == "--" &&
    //     this.coldBed == "--" &&
    //     this.stove == "--" &&
    //     this.mould == "--" &&
    //     this.tractionMachine == "--" &&
    //     this.machineHost == "--" &&
    //     this.machinePower == "--"
    //   ) {
    //     return "--";
    //   } else {
    //     return (
    //       Number(
    //         this.TotalWater != "--" ? Number(this.TotalWater).toFixed(0) : 0
    //       ) +
    //       Number(this.coldBed != "--" ? Number(this.coldBed).toFixed(0) : 0) +
    //       Number(this.stove != "--" ? Number(this.stove).toFixed(0) : 0) +
    //       Number(this.mould != "--" ? Number(this.mould).toFixed(0) : 0) +
    //       Number(
    //         this.machineHost != "--" ? Number(this.machineHost).toFixed(0) : 0
    //       ) +
    //       Number(
    //         this.machinePower != "--" ? Number(this.machinePower).toFixed(0) : 0
    //       ) +
    //       Number(
    //         this.tractionMachine != "--"
    //           ? Number(this.tractionMachine).toFixed(0)
    //           : 0
    //       )
    //     );
    //   }
    // },
  },
  components: {},
  methods: {
    screenQuery() {
      screenQuery([
        "day.output",
        "month.output",
        "year.output",
        "real.time.power",
      ]).then((res) => {
        debugger;
        if (res.code === 200) {
          debugger;
        }
      });
    },
    changedatetype() {
      if (this.tabPosition == "DAYS") {
        this.starttime = dayjs().startOf("day").format("YYYY-MM-DD 00:00:00");
        this.endtime = dayjs()
          .add(1, "day")
          .startOf("day")
          .format("YYYY-MM-DD 00:00:00");
        this.isdayyield = 1.5 * 24;
      } else if (this.tabPosition == "MONTHS") {
        this.starttime = dayjs().startOf("month").format("YYYY-MM-DD 00:00:00");
        this.endtime = dayjs()
          .add(1, "month")
          .startOf("month")
          .format("YYYY-MM-DD 00:00:00");
        this.isdayyield = 1.5 * 24 * 30;
      } else {
        this.starttime = dayjs().startOf("year").format("YYYY-MM-DD 00:00:00");
        this.endtime = dayjs()
          .add(1, "year")
          .startOf("year")
          .format("YYYY-MM-DD 00:00:00");
        this.isdayyield = 1.5 * 24 * 30 * 2;
        // this.starttime = dayjs().startOf("month").format("YYYY-MM-DD 00:00:00");
        // this.endtime = dayjs()
        //   .add(1, "month")
        //   .startOf("month")
        //   .format("YYYY-MM-DD 00:00:00");
        // this.isdayyield = 1.5 * 24 * 30;
      }

      this.gosearch();
    },
    goback() {
      this.$router.push({ name: "workshopMonitor2" });
    },
    gosearch() {
      let params = {
        endTime: this.endtime,
        energyAttrId: "Ep_imp",
        energyTypeId: "elec",
        interval: 1,
        nodeId: "838f8a3a-c8ba-4393-aabd-6ccf1cc22d0f",
        projectId: "lpsj",
        startTime: this.starttime,
        timeUnit: this.tabPosition,
        treeId: "a400519baa24400680d6245c38e7e5a1",
      };
      getWorkshopMonitoringData(params).then((res) => {
        console.log("res", res);
        if (res.data.code === 0) {
          let item = res.data.data[0].values;
          if (
            item.peace === null &&
            item.peak === null &&
            item.valley === null
          ) {
            this.TotalElectricity = "--";
          } else {
            this.TotalElectricity =
              (item.peace !== null ? Number(item.peace) : 0) +
              (item.peak !== null ? Number(item.peak) : 0) +
              (item.valley !== null ? Number(item.valley) : 0);
          }
        }
      });
      let params1 = {
        endTime: this.endtime,
        energyAttrId: "Ep_imp",
        energyTypeId: "elec",
        interval: 1,
        nodeId: "d7c7077a-0528-4f38-971e-13c2423c1561",
        projectId: "lpsj",
        startTime: this.starttime,
        timeUnit: this.tabPosition,
        treeId: "a400519baa24400680d6245c38e7e5a1",
      };
      getWorkshopMonitoringData(params1).then((res) => {
        console.log("res", res);
        if (res.data.code === 0) {
          let item = res.data.data[0].values;
          if (
            item.peace === null &&
            item.peak === null &&
            item.valley === null
          ) {
            this.TotalWater = "--";
          } else {
            this.TotalWater =
              (item.peace !== null ? Number(item.peace) : 0) +
              (item.peak !== null ? Number(item.peak) : 0) +
              (item.valley !== null ? Number(item.valley) : 0);
          }
        }
      });
      let params2 = {
        endTime: this.endtime,
        energyAttrId: "Ep_imp",
        energyTypeId: "elec",
        interval: 1,
        nodeId: "d3acdcf9-607d-4d25-96f1-99516f7d24c2",
        projectId: "lpsj",
        startTime: this.starttime,
        timeUnit: this.tabPosition,
        treeId: "a400519baa24400680d6245c38e7e5a1",
      };
      getWorkshopMonitoringData(params2).then((res) => {
        console.log("res", res);
        if (res.data.code === 0) {
          let item = res.data.data[0].values;
          if (
            item.peace === null &&
            item.peak === null &&
            item.valley === null
          ) {
            this.coldBed = "--";
          } else {
            this.coldBed =
              (item.peace !== null ? Number(item.peace) : 0) +
              (item.peak !== null ? Number(item.peak) : 0) +
              (item.valley !== null ? Number(item.valley) : 0);
          }
        }
      });
      let params3 = {
        endTime: this.endtime,
        energyAttrId: "Ep_imp",
        energyTypeId: "elec",
        interval: 1,
        nodeId: "e285b5f3-e8ec-45e9-befb-7631a403c322",
        projectId: "lpsj",
        startTime: this.starttime,
        timeUnit: this.tabPosition,
        treeId: "a400519baa24400680d6245c38e7e5a1",
      };
      getWorkshopMonitoringData(params3).then((res) => {
        console.log("res", res);
        if (res.data.code === 0) {
          let item = res.data.data[0].values;
          if (
            item.peace === null &&
            item.peak === null &&
            item.valley === null
          ) {
            this.stove = "--";
          } else {
            this.stove =
              (item.peace !== null ? Number(item.peace) : 0) +
              (item.peak !== null ? Number(item.peak) : 0) +
              (item.valley !== null ? Number(item.valley) : 0);
          }
        }
      });
      let params4 = {
        endTime: this.endtime,
        energyAttrId: "Ep_imp",
        energyTypeId: "elec",
        interval: 1,
        nodeId: "2bcc0c44-fd50-442d-ab3f-3c54acd108af",
        projectId: "lpsj",
        startTime: this.starttime,
        timeUnit: this.tabPosition,
        treeId: "a400519baa24400680d6245c38e7e5a1",
      };
      getWorkshopMonitoringData(params4).then((res) => {
        console.log("res", res);
        if (res.data.code === 0) {
          let item = res.data.data[0].values;
          if (
            item.peace === null &&
            item.peak === null &&
            item.valley === null
          ) {
            this.mould = "--";
          } else {
            this.mould =
              (item.peace !== null ? Number(item.peace) : 0) +
              (item.peak !== null ? Number(item.peak) : 0) +
              (item.valley !== null ? Number(item.valley) : 0);
          }
        }
      });
      let params5 = {
        endTime: this.endtime,
        energyAttrId: "Ep_imp",
        energyTypeId: "elec",
        interval: 1,
        nodeId: "d029bb06-10fc-4e6b-88c7-bbf64e41c0b5",
        projectId: "lpsj",
        startTime: this.starttime,
        timeUnit: this.tabPosition,
        treeId: "a400519baa24400680d6245c38e7e5a1",
      };
      getWorkshopMonitoringData(params5).then((res) => {
        console.log("res", res);
        if (res.data.code === 0) {
          let item = res.data.data[0].values;
          if (
            item.peace === null &&
            item.peak === null &&
            item.valley === null
          ) {
            this.tractionMachine = "--";
          } else {
            this.tractionMachine =
              (item.peace !== null ? Number(item.peace) : 0) +
              (item.peak !== null ? Number(item.peak) : 0) +
              (item.valley !== null ? Number(item.valley) : 0);
          }
        }
      });
      let params6 = {
        endTime: this.endtime,
        energyAttrId: "TotalFlow",
        energyTypeId: "water",
        interval: 5,
        nodeId: "a14cd206-48dc-4c6a-8457-7c728980ff0b",
        projectId: "lpsj",
        startTime: this.starttime,
        timeUnit: this.tabPosition,
        treeId: "63022b4673c7471a83ff709d5a9db18d",
      };
      getWorkshopMonitoringData(params6).then((res) => {
        console.log("res", res);
        if (res.data.code === 0) {
          let item = res.data.data[0].values;
          if (
            item.peace === null &&
            item.peak === null &&
            item.valley === null
          ) {
            this.Water = "--";
          } else {
            this.Water =
              (item.peace !== null ? Number(item.peace) : 0) +
              (item.peak !== null ? Number(item.peak) : 0) +
              (item.valley !== null ? Number(item.valley) : 0);
          }
        }
      });
      let params7 = {
        endTime: this.endtime,
        energyAttrId: "Vb",
        energyTypeId: "gas",
        interval: 1,
        nodeId: "f9ad15ad-4a36-4ea9-b81f-e9e458f9cd7c",
        projectId: "lpsj",
        startTime: this.starttime,
        timeUnit: this.tabPosition,
        treeId: "0c4ce61738724cb9876bd2db9fcb5068",
      };
      getWorkshopMonitoringData(params7).then((res) => {
        console.log("getWorkshopMonitoringData", res);
        if (res.data.code === 0) {
          let item = res.data.data[0].values;
          if (
            item.peace === null &&
            item.peak === null &&
            item.valley === null
          ) {
            this.naturalGas = "--";
          } else {
            this.naturalGas =
              (item.peace !== null ? Number(item.peace) : 0) +
              (item.peak !== null ? Number(item.peak) : 0) +
              (item.valley !== null ? Number(item.valley) : 0);
          }
        }
      });
      let params8 = {
        endTime: this.endtime,
        energyAttrId: "Vb",
        energyTypeId: "air",
        interval: 5,
        nodeId: "55e30a3a-9266-4d3f-becc-b993bd9745cd",
        projectId: "lpsj",
        startTime: this.starttime,
        timeUnit: this.tabPosition,
        treeId: "e7247a07cba74bcc867e838e5c1473a1",
      };
      getWorkshopMonitoringData(params8).then((res) => {
        console.log("res", res);
        if (res.data.code === 0) {
          let item = res.data.data[0].values;
          if (
            item.peace === null &&
            item.peak === null &&
            item.valley === null
          ) {
            this.compressedAir = "--";
          } else {
            this.compressedAir =
              (item.peace !== null ? Number(item.peace) : 0) +
              (item.peak !== null ? Number(item.peak) : 0) +
              (item.valley !== null ? Number(item.valley) : 0);
          }
        }
      });
      let params9 = {
        endTime: this.endtime,
        energyAttrId: "Vb",
        energyTypeId: "air",
        interval: 5,
        nodeId: "65b83d9a-e53a-47cd-8c91-f7b88bfc7f97",
        projectId: "lpsj",
        startTime: this.starttime,
        timeUnit: this.tabPosition,
        treeId: "e7247a07cba74bcc867e838e5c1473a1",
      };
      getWorkshopMonitoringData(params9).then((res) => {
        console.log("res", res);
        if (res.data.code === 0) {
          let item = res.data.data[0].values;
          if (
            item.peace === null &&
            item.peak === null &&
            item.valley === null
          ) {
            this.compressedAirSouth = "--";
          } else {
            this.compressedAirSouth =
              (item.peace !== null ? Number(item.peace) : 0) +
              (item.peak !== null ? Number(item.peak) : 0) +
              (item.valley !== null ? Number(item.valley) : 0);
          }
        }
      });
      let params10 = {
        endTime: this.endtime,
        energyAttrId: "Vb",
        energyTypeId: "air",
        interval: 5,
        nodeId: "67bc1867-bf16-4f34-a7f2-4ca8ae67b886",
        projectId: "lpsj",
        startTime: this.starttime,
        timeUnit: this.tabPosition,
        treeId: "e7247a07cba74bcc867e838e5c1473a1",
      };
      getWorkshopMonitoringData(params10).then((res) => {
        console.log("res", res);
        if (res.data.code === 0) {
          let item = res.data.data[0].values;
          if (
            item.peace === null &&
            item.peak === null &&
            item.valley === null
          ) {
            this.compressedAirNorth = "--";
          } else {
            this.compressedAirNorth =
              (item.peace !== null ? Number(item.peace) : 0) +
              (item.peak !== null ? Number(item.peak) : 0) +
              (item.valley !== null ? Number(item.valley) : 0);
          }
        }
      });

      let params11 = {
        endTime: this.endtime,
        energyAttrId: "Ep_imp",
        energyTypeId: "elec",
        interval: 5,
        nodeId: "ff594907-7150-4fba-afc5-f76eb0e4ce6a",
        projectId: "lpsj",
        startTime: this.starttime,
        timeUnit: this.tabPosition,
        treeId: "a400519baa24400680d6245c38e7e5a1",
      };
      getWorkshopMonitoringData(params11).then((res) => {
        console.log("res", res);
        if (res.data.code === 0) {
          let item = res.data.data[0].values;
          if (
            item.peace === null &&
            item.peak === null &&
            item.valley === null
          ) {
            this.machineHost = "--";
          } else {
            this.machineHost =
              (item.peace !== null ? Number(item.peace) : 0) +
              (item.peak !== null ? Number(item.peak) : 0) +
              (item.valley !== null ? Number(item.valley) : 0);
          }
        }
      });

      let params12 = {
        endTime: this.endtime,
        energyAttrId: "Ep_imp",
        energyTypeId: "elec",
        interval: 5,
        nodeId: "a4691378-f79d-47bb-aa81-9352403e660a",
        projectId: "lpsj",
        startTime: this.starttime,
        timeUnit: this.tabPosition,
        treeId: "a400519baa24400680d6245c38e7e5a1",
      };
      getWorkshopMonitoringData(params12).then((res) => {
        console.log("res", res);
        if (res.data.code === 0) {
          let item = res.data.data[0].values;
          if (
            item.peace === null &&
            item.peak === null &&
            item.valley === null
          ) {
            this.machinePower = "--";
          } else {
            this.machinePower =
              (item.peace !== null ? Number(item.peace) : 0) +
              (item.peak !== null ? Number(item.peak) : 0) +
              (item.valley !== null ? Number(item.valley) : 0);
          }
        }
      });
    },
  },
  mounted() {
    this.gosearch();
    // this.screenQuery();
  },
};
</script>

<style lang="less">
.MachineDetail {
  width: 100%;
  height: 100%;
  background-image: url("~@/assets/workshopMonitor/detail/backgroundimg.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  padding: 40px 13px 48px 13px;
  position: relative;
  .goback {
    color: #fff;
    font-size: 14px;
    font-weight: 400;
    position: absolute;
    top: 18px;
    right: 20px;
    cursor: pointer;
  }
  .MachineContent {
    width: 100%;
    height: 100%;
    background-image: url("~@/assets/workshopMonitor/detail/back2.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
    display: flex;
    justify-content: space-around;
    padding: 1% 0.9% 1% 1.5%;
    .leftContent {
      width: calc(100% - 27.4%);
      height: 100%;
      // background: #6bdf7c8f;
      position: relative;
      .seltab {
        display: flex;
        position: absolute;
        top: 20px;
        right: 20px;
        color: #fff;
        font-size: 12px;
        line-height: 24px;
      }
      .lefttab {
        width: 178px;
        height: 216px;
        background-image: url("~@/assets/workshopMonitor/detail/lfTab.png");
        position: absolute;

        .tabtit {
          width: 100%;
          height: 28px;
          text-align: center;
          font-size: 14px;
          color: #fff;
          line-height: 28px;
        }
        .num1 {
          width: 100%;
          height: 40px;
          line-height: 40px;
          color: #fff;
          font-size: 34px;
          text-align: center;
          position: relative;
          top: -84px;
        }
        .num2 {
          width: 100%;
          height: 12px;
          line-height: 12px;
          color: #fff;
          font-size: 12px;
          text-align: center;
          position: relative;
          top: -84px;
        }
      }
      .bigbg {
        width: 674px;
        height: 614px;
        background-image: url("~@/assets/workshopMonitor/detail/bigbmg.png");
        background-repeat: no-repeat;
        background-size: 100% 100%;
        position: absolute;
        left: 20%;
        top: 10%;
        .jtit {
          width: 250px;
          height: 65px;
          background-image: url(/img/jztit.6ed68f29.png);
          background-repeat: no-repeat;
          background-size: 100% 100%;
          font-size: 22px;
          color: #fff;
          text-align: center;
          line-height: 65px;
          font-weight: 900;
          position: absolute;
          left: 50%;
          transform: translate(-44%, 0);
          top: 90px;
        }
        .sclogo {
          position: absolute;
          top: 175px;
          left: 50%;
          transform: translate(-44%, 0);
          border-radius: 11px;
          border: 1px solid #2695b1;
          color: #30fff7;
          padding: 5px;
          font-size: 14px;
          width: 170px;
          text-align: center;
          display: flex;
          align-items: center;
          justify-content: space-between;
          letter-spacing: 0.5px;
          span {
            display: flex;
            align-items: center;
            justify-content: space-between;
          }
          span::before {
            content: " ";
            width: 6px;
            height: 6px;
            background: #30fff7;
            box-shadow: 0px 0px 8px 0px #30fff7;
            border: 1px solid #c1f2f0;
            display: block;
            border-radius: 50%;
            margin-right: 5px;
          }
        }
        .jt {
          position: absolute;
          bottom: 20px;
          left: 50%;
          transform: translate(-45%, 0);
        }
      }
    }
    .rightContent {
      width: 26.7%;
      margin-left: 0.7%;
      // background: #6bdf7c8f;
      .riHead {
        height: 50px;
        width: 100%;
        text-align: center;
        border-bottom: 1px solid rgba(255, 255, 255, 0.1);
        display: flex;
        justify-content: center;
        span {
          display: inline-block;
          padding: 0 20px;
          height: 100%;
          line-height: 50px;
          font-weight: 500;
          font-size: 16px;
          border-bottom: 4px solid #3f6df8;
          color: #fff;
        }
      }
      .ritMsg {
        position: relative;
        width: 100%;
        height: calc(100% - 60px);
        // background: #6bdf7c8f;
        padding-right: 26px;
        padding-left: 38px;
        padding-top: 2%;

        .tabsimg1 {
          width: 100%;
          height: 50px;
          background-image: url("~@/assets/workshopMonitor/detail/tabsimg1.png");
          background-size: 100% 100%;
          background-repeat: no-repeat;
          display: flex;
          align-items: center;
          .t1 {
            display: inline-block;
            position: absolute;
            right: 66px;
            width: 180px;
            color: #f8e71c;
            font-size: 26px;
            // font-weight: bold;
            text-align: right;
            line-height: 48px;
            span {
              font-size: 12px;
              font-weight: 400;
              color: #ffffff;
            }
          }
          .direction {
            width: 92px;
            height: 30px;
            position: absolute;
            left: 10px;
            background-image: url("~@/assets/workshopMonitor/detail/direction.png");
            background-size: 100% 100%;
            background-repeat: no-repeat;
            margin-top: -6px;
            .icon {
            }
            .t2 {
              font-size: 14px;
              font-weight: 500;
              color: #ffffff;
              position: absolute;
              right: -24px;
              line-height: 32px;
            }
          }
          .tab2 {
            background-image: url("~@/assets/workshopMonitor/detail/diricon2.png");
          }
          .tab3 {
            background-image: url("~@/assets/workshopMonitor/detail/diricon3.png");
          }
          .tab4 {
            background-image: url("~@/assets/workshopMonitor/detail/diricon4.png");
          }
        }
        .bg1 {
          background-image: url("~@/assets/workshopMonitor/detail/trq.png");
          margin-top: 2vh;
        }
        .bg2 {
          background-image: url("~@/assets/workshopMonitor/detail/yskq.png");
          margin-top: 2vh;
        }
        .tabs {
          margin-top: 10px;
          margin-bottom: 20px;
          .atab {
            width: 300px;
            height: 50px;
            background-image: url("~@/assets/workshopMonitor/detail/atab.png");
            margin-top: -12px;
            display: flex;
            justify-content: flex-end;
            align-items: flex-end;
            text-align: right;
            .leftTxt {
              width: 21%;
              height: 56%;
              margin-right: 5px;
              text-align: center;
              display: flex;
              justify-content: center;
              align-items: center;
              font-size: 14px;
              font-weight: 500;
              color: #ffffff;
            }
            .ritTxt {
              width: 50%;
              height: 56%;
              padding-right: 18px;
              font-size: 22px;
              font-weight: bold;
              color: #5ff1ff;
              display: flex;
              justify-content: flex-end;
              align-items: center;
              span {
                margin-left: 0.5rem;
                font-weight: 400;
                color: #fff;
                font-size: 12px;
              }
            }
          }
        }
      }
    }
  }
  .el-radio-button--mini .el-radio-button__inner {
    padding: 5px 15px;
  }
  .el-radio-button__orig-radio:checked + .el-radio-button__inner {
    background: #3f6df8;
    border: 1px solid #3f6df8;
  }
}
</style>
